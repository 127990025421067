<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Ingresar comprobante de compra</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Type document -->
        <div>
          <label class="text-sm opacity-75">Tipo de comprobante*</label>
          <v-select @input="invoiceRef={}, document.currency={}, document.provider =  null" :options="typesInvoices"
                    :clearable="false"
                    label="value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.typeInvoice" class="mb-4 md:mb-0" name="typeInvoice" id="typeInvoice"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('typeInvoice')">{{ errors.first('typeInvoice') }}</span>
        </div>
        <!--If type document is credit note-->
        <div class="mt-5" v-if="document.typeInvoice && document.typeInvoice.id ==='07'">
          <div class="vx-row mt-5">
            <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
              <vs-input :disabled="this.invoiceRef.id!== undefined"
                        @blur="invoiceRef.serie= trimString(invoiceRef.serie)"
                        label="Serie"
                        v-model="invoiceRef.serie"
                        class="w-full" name="serie"
                        v-validate="'required|max:4'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('serie')">{{ errors.first('serie') }}</span>
            </div>
            <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
              <vs-input :disabled="this.invoiceRef.id!== undefined"
                        @blur="invoiceRef.number= trimString(invoiceRef.number)"
                        label="Nro. Doc"
                        v-model="invoiceRef.number"
                        class="w-full" name="number"
                        v-validate="'required|max:10'"/>
              <span class="text-danger text-sm"
                    v-show="errors.has('number')">{{ errors.first('number') }}</span>
            </div>
          </div>
          <vs-button @click="getInvoice" class="mt-2" style="width: 100%">Buscar</vs-button>
        </div>
        <!-- Type invoice -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Tipo de bien o servicio*</label>
          <v-select :disabled="this.invoiceRef.id!== undefined" :options="types" :clearable="false"
                    label="value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.type" class="mb-4 md:mb-0" name="type" id="type"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>
        <!-- Gloss -->
        <div class="mt-5">
          <vs-input @blur="document.gloss= trimString(document.gloss, true)" label="Glosa*"
                    v-model="document.gloss"
                    class="w-full" name="gloss"
                    v-validate="'required|max:100|min:5'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('gloss')">{{ errors.first('gloss') }}</span>
        </div>
        <!-- Currency -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Moneda*</label>
          <v-select :disabled="this.invoiceRef.id!== undefined" :options="currencies" :clearable="false"
                    label="value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.currency" class="mb-4 md:mb-0" name="currency" id="currency"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('currency')">{{ errors.first('currency') }}</span>
        </div>
        <!--Dates-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="vs-select--label" for="dateOfIssue">Fecha de emisión*</label>
            <datepicker :language="es" label="Fecha de emisión" v-model="document.dateOfIssue" id="dateOfIssue"
                        v-validate="'required'" name="dateOfIssue"></datepicker>
            <span class="text-danger text-sm"
                  v-show="errors.has('dateOfIssue')">{{ errors.first('dateOfIssue') }}</span>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="vs-select--label" for="endDate">Fecha de vencimiento*</label>
            <datepicker :language="es" label="Fecha de vencimiento" v-model="document.endDate" id="endDate"
                        v-validate="'required'" name="endDate"></datepicker>
            <span class="text-danger text-sm"
                  v-show="errors.has('endDate')">{{ errors.first('endDate') }}</span>
          </div>
        </div>
        <!--Doc-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input @blur="document.serie= trimString(document.serie)" label="Serie*"
                      v-model="document.serie"
                      class="w-full" name="serie"
                      v-validate="'required|max:4'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('serie')">{{ errors.first('serie') }}</span>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input @blur="document.number= trimString(document.number)" label="Nro. Doc*"
                      v-model="document.number"
                      class="w-full" name="number"
                      v-validate="'required|max:10'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('number')">{{ errors.first('number') }}</span>
          </div>
        </div>
        <!--Providers-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Proveedor*</label>
          <v-select :disabled="this.invoiceRef.id !== undefined" :options="providers" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.provider" class="mb-4 md:mb-0" name="provider" id="provider"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('provider')">{{ errors.first('provider') }}</span>
        </div>
        <!--Apply igv-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Afecto IGV*</label>
          <v-select :options="optionsIgv" :clearable="false"
                    label="value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.applyIgv" class="mb-4 md:mb-0" name="applyIgv" id="applyIgv"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('applyIgv')">{{ errors.first('applyIgv') }}</span>
        </div>
        <!--Payment method-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Forma de pago*</label>
          <v-select :options="paymentMethods" :clearable="false"
                    label="value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.paymentMethod" class="mb-4 md:mb-0" name="paymentMethod" id="paymentMethod"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('paymentMethod')">{{ errors.first('paymentMethod') }}</span>
        </div>
        <!--Amounts-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input
                @input="document.igv= parseFloat((document.base*0.18).toFixed(2)), document.total = parseFloat((document.base +  document.igv + document.unaffected).toFixed(2))"
                type="number"
                :label="`Base imponible* (${document.currency.symbol})`"
                v-model.number="document.base"
                class="w-full" name="base"
                v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('base')">{{ errors.first('base') }}</span>
          </div>
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input @input="document.total = (document.base +  document.igv + document.unaffected)" type="number"
                      :label="`Inafecto* (${document.currency.symbol})`"
                      v-model.number="document.unaffected"
                      class="w-full" name="unaffected"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('unaffected')">{{ errors.first('unaffected') }}</span>
          </div>
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input disabled="true" type="number"
                      :label="`IGV* (${document.currency.symbol})`"
                      v-model.number="document.igv"
                      class="w-full" name="igv"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('igv')">{{ errors.first('igv') }}</span>
          </div>
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input disabled="true" type="number"
                      :label="`Total* (${document.currency.symbol})`"
                      v-model.number="document.total"
                      class="w-full" name="total"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('total')">{{ errors.first('total') }}</span>
          </div>
        </div>
        <!--Accounts-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Cuenta contable*</label>
          <v-select :options="accounts" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.account" class="mb-4 md:mb-0" name="account" id="account"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('account')">{{ errors.first('account') }}</span>
        </div>
        <!--Costs-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Centro de costos*</label>
          <v-select :options="costs" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.cost" class="mb-4 md:mb-0" name="cost" id="cost"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('cost')">{{ errors.first('cost') }}</span>
        </div>
        <!--Orders-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Selecciona orden de compra</label>
          <v-select label="numberOfDocument" :options="orders" :clearable="true"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.order" class="mb-4 md:mb-0" name="order"/>
        </div>
        <!--End-->
        <!--Add products-->
        <template v-if="document.type && document.type.id ===1">
          <hr class="mt-5 mb-5">
          <!--Warehouse-->
          <div>
            <label class="text-sm opacity-75">Almacén</label>
            <v-select label="name" :options="warehouses" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.warehouse" class="mb-4 md:mb-0" name="warehouse" v-validate="'required'"/>

            <span class="text-danger text-sm"
                  v-show="errors.has('warehouse')">{{ errors.first('warehouse') }}</span>
          </div>
          <!--Products-->
          <div class="mt-5" v-if="document.typeInvoice.id ==='01'">
            <label class="text-sm opacity-75">Selecciona producto</label>
            <v-select :options="allProducts" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="selectedProduct" class="mb-4 md:mb-0" name="product" v-validate="'required'"/>
          </div>
          <!--End-->
          <vs-button class="mt-5" @click="addProduct">Agregar</vs-button>

          <!--Added products-->
          <vs-table class="mt-5" style="padding-bottom: 14rem" :data="addedProducts">

            <template slot="thead">
              <vs-th>Nombre</vs-th>
              <vs-th>SKU</vs-th>
              <vs-th>Categoría</vs-th>
              <vs-th>Marca</vs-th>
              <vs-th>Cantidad</vs-th>
              <vs-th>Costo ({{ document.currency.symbol }})</vs-th>
              <vs-th>Eliminar</vs-th>
              <vs-th>Series de Fábrica</vs-th>
              <vs-th>Series Internas</vs-th>
              <vs-th>Descargar</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.name">
                  <p>{{ tr.name }}</p>
                </vs-td>

                <vs-td :data="tr.sku">
                  <p> {{ tr.sku }}</p>
                </vs-td>

                <vs-td :data="tr.category">
                  <p>{{ tr.category.name }}</p>
                </vs-td>

                <vs-td :data="tr.brand">
                  <p>{{ tr.brand.name }}</p>
                </vs-td>

                <vs-td :data="tr.quantity">
                  <template>
                    <vs-input min="1" :max="tr.stock" style="width: 80px" type="number" v-model.number="tr.quantity"/>
                  </template>
                </vs-td>

                <vs-td :data="tr.cost">
                  <template>
                    <vs-input :max="tr.cost" style="width: 90px" type="number" v-model.number="tr.cost"/>
                  </template>
                </vs-td>

                <vs-td class="whitespace-no-wrap">
                  <feather-icon class="ml-2" icon="TrashIcon"
                                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                @click.stop="deleteProduct(tr)"/>
                </vs-td>

                <vs-td class="whitespace-no-wrap">
                  <feather-icon class="ml-2" icon="PlusIcon"
                                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                @click.stop="openDialog(tr)"/>
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <feather-icon class="ml-2" icon="PlusIcon"
                                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                @click.stop="openDialogForInternalSerials(tr)"/>
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <feather-icon class="ml-2" icon="DownloadIcon"
                                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                @click.stop="exportToExcelTemplate(tr)"/>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <!---->
        </template>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="confirmSubmit" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>


    <vs-prompt
        @cancel="clearDialog"
        @accept="acceptDialog"
        @close="closeDialog"
        acceptText="Aceptar"
        cancelText="Cancelar"
        title="Series de fábrica"
        :active.sync="openSerialDialog">
      <div class="con-exemple-prompt">
        <vs-textarea rows="5" label="Series" v-model="serials" class="mt-4 mb-2 w-full"/>
      </div>
    </vs-prompt>

    <vs-prompt
        @cancel="clearDialogForSerials"
        @accept="acceptDialogInternalSerials"
        @close="closeDialogForInternalSerials"
        acceptText="Aceptar"
        cancelText="Cancelar"
        title="Series internas"
        :active.sync="openIntSerialDialog">
      <div class="con-exemple-prompt">
        <vs-button @click="generateInternalSerials" class="mb-4">Generar</vs-button>
        <vs-textarea disabled rows="5" label="Series internas" v-model="internalSerialsText" class="mt-4 mb-2 w-full"/>
      </div>
    </vs-prompt>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import Datepicker from 'vuejs-datepicker'

import { auth, db, FieldValue } from '../../firebase/firebaseConfig'

import { es } from 'vuejs-datepicker/dist/locale'

const _ = require('lodash')

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    providers: {
      type: Array,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    },
    costs: {
      type: Array,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    orders: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  mixins: [trimString],
  data() {
    return {
      document: {
        base: 0,
        unaffected: 0,
        igv: 0,
        total: 0,
        currency: {
          symbol: ''
        },
        type: null,
        typeInvoice: null
      },
      invoiceRef: {},
      types: [
        {
          id: 1,
          value: 'Mercadería, Materia prima, suministros diversos envases y embalajes.'
        },
        {
          id: 2,
          value: 'Activo fijo.'
        },
        {
          id: 3,
          value: 'Otros activos no considerados en los numerales 1 y 2.'
        },
        {
          id: 4,
          value: 'Gastos de educación, recreación, salud, culturales, representación, capacitación, de viaje, mantenimiento de vehículo y de premios.'
        },
        {
          id: 5,
          value: 'Otros gastos no incluidos en el numeral 4.'
        }
      ],
      currencies: [
        {
          id: 1,
          value: 'Soles',
          symbol: 'S/',
          code: 'PEN'
        },
        {
          id: 2,
          value: 'Dólares',
          symbol: '$',
          code: 'USD'
        }
      ],
      optionsIgv: [
        {
          id: 1,
          value: 'Adquisiciones gravadas destinadas a operaciones gravadas'
        },
        {
          id: 2,
          value: 'Adquisiciones gravadas destinadas a operaciones gravadas y no gravadas'
        },
        {
          id: 3,
          value: 'Adquisiciones gravadas destinadas a operaciones gravadas y no gravadas'
        },
        {
          id: 4,
          value: 'Aquisiciones no gravadas'
        },
        {
          id: 5,
          value: 'Adquisiciones con mas de un destino'
        }
      ],
      paymentMethods: [
        {
          id: '001',
          value: 'Contado'
        },
        {
          id: '002',
          value: 'Crédito'
        }
      ],
      typesInvoices: [
        {
          id: '01',
          value: 'Factura'
        },
        {
          id: '02',
          value: 'Recibo por honorario'
        },
        {
          id: '07',
          value: 'Nota de crédito'
        },
        {
          id: '14',
          value: 'Recibo por servicios públicos'
        },
        {
          id: '13',
          value: ' Documentos emitidos por las empresas del sistema financiero y de seguros, y por las cooperativas de ahorro y crédito'
        },
        {
          id: '15',
          value: 'Boletos emitidos por el servicio de transporte terrestre '
        },
        {
          id: '12',
          value: 'Ticket o cinta emitido por máquina registradora'
        },
        {
          id: '16',
          value: 'Boletos de viaje emitidos por las empresas de transporte nacional de pasajeros.'
        }
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      deleteImageFlag: false,
      selectedProduct: {},

      openSerialDialog: false,
      serials: [],

      internalSerials: [],
      internalSerialsText: '',
      openIntSerialDialog: false,

      es: es,
      addedProducts: [],
      allProducts: []
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.document = {
          base: 0,
          unaffected: 0,
          igv: 0,
          total: 0,
          currency: {
            symbol: ''
          },
          type: null,
          typeInvoice: null
        }
        this.allProducts = _.cloneDeep(this.products)
        this.addedProducts = []
        this.invoiceRef = {}
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    async confirmSubmit() {
      if (this.validateInternalSerials()) {
        const result = await this.$validator.validateAll()
        if (result) {
          this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: '¡Atención!',
            text: `Luego de guardar el comprobante no podrás editarlo. ¿Deseas guardar el comprobante?`,
            accept: this.submitData,
            cancel: null,
            parameters: null,
            acceptText: 'Guardar',
            cancelText: 'Cancelar'
          })
        }
      }
      else {
        this.$vs.notify({
          color: 'warning',
          title: 'Comprobante de compra',
          text: 'Debes ingresar las series internas de todos los productos.'
        })
      }
    },
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData() {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          if (this.document.type.id === 1 && this.document.typeInvoice.id === '01') {
            if (this.validateQuantityNoZero()) {

              this.progress = true
              const formatForSerie = '0000'
              const formatForDoc = '0000000000'

              const products = []
              this.addedProducts.forEach((p) => {
                let obj = _.cloneDeep(p)
                delete obj.interialSerialsArray
                products.push(obj)
              })

              let obj = {
                ...this.document,
                state: true,
                serie: formatForSerie.substr(this.document.serie.length) + this.document.serie,
                number: formatForDoc.substr(this.document.number.length) + this.document.number,
                products,
                paidOut: 0,
                addedSeries: true
              }

              const doc = await db.collection('invoices').add({
                ...obj,
                createdAt: FieldValue.serverTimestamp(),
                uid: auth.currentUser.uid,
                user: {
                  uid: auth.currentUser.uid,
                  displayName: auth.currentUser.displayName
                }
              })

              obj.id = doc.id
              obj.createdAt = new Date()


              // Save products serials
              await this.saveInternalSerials(obj.id, obj.dateOfIssue)
              // End

              this.$emit('add', obj)
              this.$emit('closeSidebar')

              this.$vs.notify({
                color: 'success',
                title: 'Comprobante de compra',
                text: 'Comprobante ingresado correctamente.'
              })
            }
            else {
              this.$vs.notify({
                color: 'warning',
                title: 'Error',
                text: 'Información incompleta de productos.'
              })
            }
          }
          else if (this.document.type.id === 1 && this.document.typeInvoice.id === '07') {
            if (this.invoiceRef.id) {
              this.progress = true
              const formatForSerie = '0000'
              const formatForDoc = '0000000000'
              let obj = {
                ...this.document,
                state: true,
                serie: formatForSerie.substr(this.document.serie.length) + this.document.serie,
                number: formatForDoc.substr(this.document.number.length) + this.document.number,
                products: [...this.addedProducts],
                invoiceRef: this.invoiceRef
              }
              const doc = await db.collection('invoices').add({
                ...obj,
                createdAt: FieldValue.serverTimestamp(),
                uid: auth.currentUser.uid,
                user: {
                  uid: auth.currentUser.uid,
                  displayName: auth.currentUser.displayName
                }
              })
              obj.id = doc.id
              obj.createdAt = new Date()
              this.$emit('add', obj)
              this.$emit('closeSidebar')
              this.progress = false
              this.$vs.notify({
                color: 'success',
                title: 'Comprobante de compra',
                text: 'Comprobante ingresado correctamente.'
              })
            }
            else {
              this.$vs.notify({
                color: 'warning',
                title: 'Error',
                text: 'Debes buscar comprobante a anular.'
              })
            }
          }
          else {
            this.progress = true
            const formatForSerie = '0000'
            const formatForDoc = '0000000000'
            let obj = {
              ...this.document,
              serie: formatForSerie.substr(this.document.serie.length) + this.document.serie,
              number: formatForDoc.substr(this.document.number.length) + this.document.number,
              state: true,
              paidOut: 0
            }
            const doc = await db.collection('invoices').add({
              ...obj,
              createdAt: FieldValue.serverTimestamp(),
              uid: auth.currentUser.uid
            })
            obj.id = doc.id
            obj.createdAt = new Date()
            this.$emit('add', obj)
            this.$emit('closeSidebar')
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Comprobante de compra',
              text: 'Comprobante ingresado correctamente.'
            })
          }
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Validate internal serials products
     */
    validateInternalSerials () {
      let flag = true
      for (let i = 0; i < this.addedProducts.length; i++) {
        let p = this.addedProducts[i]
        if (!p.interialSerialsArray || p.interialSerialsArray.length === 0 || p.interialSerialsArray.length !== p.quantity) {
          flag = false
          break
        }
      }
      return flag
    },
    /**
     * Save internal serials
     */
    async saveInternalSerials (invoiceId, dateOfIssue) {
      const batch = db.batch()
      const arrayBatch = []
      arrayBatch.push(batch)
      let currentBatch = 0
      let countDocuments = 0

      const formatForSerial = '0000'
      const formatForDoc = '0000000000'
      this.addedProducts.forEach((p) => {
        p.interialSerialsArray.forEach((s) => {
          const docRef = db.collection('serials').doc(s)
          arrayBatch[currentBatch].set(docRef, {
            createdAt: FieldValue.serverTimestamp(),
            dateOfPurchase: dateOfIssue,
            productId: p.id,
            productSku: p.sku,
            purchaseInvoiceId: invoiceId,
            purchaseInvoiceSerial: formatForSerial.substr(this.document.serie.length) + this.document.serie,
            purchaseInvoiceNumber: formatForDoc.substr(this.document.number.length) + this.document.number,
            cost: p.cost,
            currency: this.document.currency,
            state: true,
            provider: this.document.provider,
            status: 'free'
          })
          countDocuments++

          if (countDocuments === 499) {
            arrayBatch.push(db.batch())
            countDocuments = 0
            currentBatch++
          }
        })
        delete p.interialSerialsArray
      })

      // Commit batch
      for await (let b of arrayBatch) {
        await b.commit()
      }
      // End
    },
    /**
     * Add product
     */
    addProduct () {
      if (this.selectedProduct.id) {
        this.addedProducts.push({
          ...this.selectedProduct,
          quantity: 1,
          cost: 0
        })
        const indexProduct = this.allProducts.findIndex((p) => p.id === this.selectedProduct.id)
        this.allProducts.splice(indexProduct, 1)
        this.selectedProduct = {}
      }
      else {
        this.$vs.notify({
          color: 'danger',
          title: 'Error',
          text: 'Selecciona un producto'
        })
      }
    },
    /**
     * Delete product
     */
    deleteProduct(product) {
      this.allProducts.push(product)
      const indexProduct = this.addedProducts.findIndex((p) => p.id === product.id)
      this.addedProducts.splice(indexProduct, 1)
    },
    /**
     * Validate that no product has stock 0
     * */
    validateQuantityNoZero() {
      let flag = true
      if (this.addedProducts.length === 0 && this.document.typeInvoice === '01') {
        flag = false
      }
      this.addedProducts.forEach((p) => {
        flag = (p.quantity > 0 && p.cost > 0)
      })
      return flag
    },
    /**
     * Get Invoice
     */
    async getInvoice() {
      try {
        if (this.invoiceRef.number && this.invoiceRef.serie) {
          const formatForSerie = '0000'
          const formatForDoc = '0000000000'
          const serie = formatForSerie.substr(this.invoiceRef.serie.length) + this.invoiceRef.serie
          const number = formatForDoc.substr(this.invoiceRef.number.length) + this.invoiceRef.number
          this.progress = true
          const querySnap = await db.collection('invoices').where('serie', '==', serie).where('number', '==', number).get()
          querySnap.forEach((doc) => {
            this.invoiceRef = {
              id: doc.id,
              base: doc.data().base,
              igv: doc.data().igv,
              unaffected: doc.data().unaffected,
              total: doc.data().total,
              serie: doc.data().serie,
              number: doc.data().number,
              provider: doc.data().provider,
              currency: doc.data().currency,
              type: doc.data().type
            }
            if (doc.data().products) {
              this.addedProducts = [...doc.data().products]
            }
          })
          if (this.invoiceRef.id) {
            this.document.provider = this.providers.find((o) => o.id === this.invoiceRef.provider.id)
            this.document.currency = this.currencies.find((o) => o.id === this.invoiceRef.currency.id)
            this.document.type = this.types.find((o) => o.id === this.invoiceRef.type.id)
            this.$vs.notify({
              color: 'success',
              title: 'Comprobante de compra',
              text: 'Comprobante encontrado.'
            })
          } else {
            this.$vs.notify({
              color: 'warning',
              title: 'Error',
              text: 'Comprobante no encontrado'
            })
          }
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Open Dialog for serials
     * */
    openDialog (p) {
      this.selectedProduct = p
      this.openSerialDialog = true
      if (p.serials) {
        this.serials = p.serials
      }
    },
    /**
     * Close Dialog for serial
     */
    clearDialog () {
      this.serials = []
    },
    /**
     * Accept Dialog for serial
     */
    acceptDialog () {
      const productIndex = this.addedProducts.findIndex((o) => {
        return this.selectedProduct.id === o.id
      })
      this.addedProducts[productIndex].serials = _.cloneDeep(this.serials)
      this.addedProducts[productIndex].serialsArray = _.cloneDeep(this.serials).split('\n');
      this.selectedProduct = {}
      this.serials = ''
      this.openSerialDialog = false
    },
    /**
     * Close dialog
     */
    closeDialog () {
      this.selectedProduct = {}
      this.openSerialDialog = false
      this.serials = []
    },
    /**
     * Open Dialog for internal serials
     * */
    openDialogForInternalSerials (p) {
      if (this.document.provider) {
        this.selectedProduct = p
        this.openIntSerialDialog = true
        if (p.interialSerialsArray) {
          this.internalSerials = p.interialSerialsArray
          this.internalSerialsText = p.interialSerialsArray.join('\n')
        }
      }
      else {
        this.$vs.notify({
          color: 'warning',
          title: 'Comprobante de compra',
          text: 'Debes elegir proveedor.'
        })
      }
    },
    /**
     * Close Dialog for internal serials
     */
    clearDialogForSerials () {
      this.internalSerials = []
      this.internalSerialsText = ''
    },
    /**
     * Accept Dialog for internal serials
     */
    acceptDialogInternalSerials () {
      const productIndex = this.addedProducts.findIndex((o) => {
        return this.selectedProduct.id === o.id
      })
      this.addedProducts[productIndex].interialSerialsArray = _.cloneDeep(this.internalSerials)
      this.selectedProduct = {}
      this.internalSerials = []
      this.internalSerialsText = ''
      this.openIntSerialDialog = false
    },
    /**
     * Close dialog for internal serials
     */
    closeDialogForInternalSerials () {
      this.selectedProduct = {}
      this.openIntSerialDialog = false
      this.internalSerials = []
      this.internalSerialsText = ''
    },
    /**
     * Generate internal serial
     */
    generateInternalSerials () {
      const uniqueSerials = []
      for (let i = 0; i < this.selectedProduct.quantity; i++) {
        const timestamp = new Date().getTime() + i
        const randomCode = Math.floor(Math.random() * 10000)
        uniqueSerials.push(timestamp + randomCode + "")
      }
      this.internalSerials = [...uniqueSerials]
      this.internalSerialsText = uniqueSerials.join('\n')
    },
    /**
     * Export serials for print
     */
    exportToExcelTemplate (p) {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(p.interialSerialsArray)
        const newList = []
        const headerVal = ['sku', 'serial']
        const headerTitle = ['sku', 'serial']
        list.forEach((l) => {
          newList.push({
            sku: p.sku,
            serial: l
          })
        })
        const data = this.formatJson(headerVal, newList)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: p.sku + '-Serials ' + this.document.serie + '-' + this.document.number + '-' + new Date().getTime(),
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
      })
    },
    /**
     * Format json
     * @param filterVal
     * @param jsonData
     * @returns {*}
     */
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 80vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
