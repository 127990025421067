<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Detalle de comprobante</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Generate series--->
        <vs-button v-if="!document.addedSeries" class="w-full mb-8" @click="saveInternalSerials">Generar series
          internas
        </vs-button>
        <!--End-->
        <!-- Type document -->
        <div>
          <label class="text-sm opacity-75">Tipo de comprobante</label>
          <v-select disabled :options="typesInvoices" :clearable="false"
                    label="value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.typeInvoice" class="mb-4 md:mb-0" name="typeInvoice" id="typeInvoice"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('typeInvoice')">{{ errors.first('typeInvoice') }}</span>
        </div>
        <!-- Type invoice -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Tipo de bien o servicio</label>
          <v-select disabled :options="types" :clearable="false"
                    label="value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.type" class="mb-4 md:mb-0" name="type" id="type"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>
        <!-- Gloss -->
        <div class="mt-5">
          <vs-input disabled @blur="document.gloss= trimString(document.gloss, true)" label="Glosa"
                    v-model="document.gloss"
                    class="w-full" name="gloss"
                    v-validate="'required|max:100|min:5'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('gloss')">{{ errors.first('gloss') }}</span>
        </div>
        <!-- Currency -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Moneda</label>
          <v-select disabled :options="currencies" :clearable="false"
                    label="value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.currency" class="mb-4 md:mb-0" name="currency" id="currency"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('currency')">{{ errors.first('currency') }}</span>
        </div>
        <!--Dates-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="vs-select--label" for="dateOfIssue">Fecha de emisión</label>
            <datepicker disabled :language="es" label="Fecha de emisión" v-model="document.dateOfIssue" id="dateOfIssue"
                        v-validate="'required'" name="dateOfIssue"></datepicker>
            <span class="text-danger text-sm"
                  v-show="errors.has('dateOfIssue')">{{ errors.first('dateOfIssue') }}</span>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="vs-select--label" for="endDate">Fecha de vencimiento</label>
            <datepicker disabled :language="es" label="Fecha de vencimiento" v-model="document.endDate" id="endDate"
                        v-validate="'required'" name="endDate"></datepicker>
            <span class="text-danger text-sm"
                  v-show="errors.has('endDate')">{{ errors.first('endDate') }}</span>
          </div>
        </div>
        <!--Doc-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input disabled @blur="document.serie= trimString(document.serie)" label="Serie"
                      v-model="document.serie"
                      class="w-full" name="serie"
                      v-validate="'required|max:4'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('serie')">{{ errors.first('serie') }}</span>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input disabled @blur="document.number= trimString(document.number)" label="Nro. Doc"
                      v-model="document.number"
                      class="w-full" name="number"
                      v-validate="'required|max:10'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('number')">{{ errors.first('number') }}</span>
          </div>
        </div>
        <!--Providers-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Proveedor</label>
          <v-select disabled :options="providers" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.provider" class="mb-4 md:mb-0" name="provider" id="provider"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('provider')">{{ errors.first('provider') }}</span>
        </div>
        <!--Apply igv-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Afecto IGV</label>
          <v-select disabled :options="optionsIgv" :clearable="false"
                    label="value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.applyIgv" class="mb-4 md:mb-0" name="applyIgv" id="applyIgv"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('applyIgv')">{{ errors.first('applyIgv') }}</span>
        </div>
        <!--Payment method-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Forma de pago</label>
          <v-select disabled :options="paymentMethods" :clearable="false"
                    label="value"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.paymentMethod" class="mb-4 md:mb-0" name="paymentMethod" id="paymentMethod"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('paymentMethod')">{{ errors.first('paymentMethod') }}</span>
        </div>
        <!--Amounts-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input
                disabled
                @input="document.igv= parseFloat((document.base*0.18).toFixed(2)), document.total = parseFloat((document.base +  document.igv + document.unaffected).toFixed(2))"
                type="number"
                :label="`Base imponible (${document.currency.symbol})`"
                v-model.number="document.base"
                class="w-full" name="base"
                v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('base')">{{ errors.first('base') }}</span>
          </div>
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input disabled @input="document.total = (document.base +  document.igv + document.unaffected)"
                      type="number"
                      :label="`Inafecto (${document.currency.symbol})`"
                      v-model.number="document.unaffected"
                      class="w-full" name="unaffected"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('unaffected')">{{ errors.first('unaffected') }}</span>
          </div>
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input disabled="true" type="number"
                      :label="`IGV (${document.currency.symbol})`"
                      v-model.number="document.igv"
                      class="w-full" name="igv"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('igv')">{{ errors.first('igv') }}</span>
          </div>
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input disabled="true" type="number"
                      :label="`Total (${document.currency.symbol})`"
                      v-model.number="document.total"
                      class="w-full" name="total"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('total')">{{ errors.first('total') }}</span>
          </div>
        </div>
        <!--Accounts-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Cuenta contable</label>
          <v-select disabled :options="accounts" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.account" class="mb-4 md:mb-0" name="account" id="account"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('account')">{{ errors.first('account') }}</span>
        </div>
        <!--Costs-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Centro de costos</label>
          <v-select disabled :options="costs" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.cost" class="mb-4 md:mb-0" name="cost" id="cost"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('cost')">{{ errors.first('cost') }}</span>
        </div>
        <!--Order-->
        <div class="mt-5" v-if="document.order">
          <vs-input class="w-full" label="Número de orden de compra" disabled="true"
                    :value="document.order.numberOfDocument"></vs-input>
        </div>
        <!--Products-->
        <template v-if="document.type && document.type.id ===1">
          <hr class="mt-5 mb-5">
          <!--Added products-->
          <vs-table class="mt-5" style="padding-bottom: 14rem" :data="addedProducts">

            <template slot="thead">
              <vs-th>Nombre</vs-th>
              <vs-th>SKU</vs-th>
              <vs-th>Categoría</vs-th>
              <vs-th>Marca</vs-th>
              <vs-th>Cantidad</vs-th>
              <vs-th>Costo ({{ document.currency.symbol }})</vs-th>
              <vs-th>Series Internas</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.name">
                  <p>{{ tr.name }}</p>
                </vs-td>

                <vs-td :data="tr.sku">
                  <p> {{ tr.sku }}</p>
                </vs-td>

                <vs-td :data="tr.category">
                  <p>{{ tr.category.name }}</p>
                </vs-td>

                <vs-td :data="tr.brand">
                  <p>{{ tr.brand.name }}</p>
                </vs-td>

                <vs-td :data="tr.quantity">
                  <template>
                    <vs-input disabled min="1" :max="tr.stock" style="width: 80px" type="number"
                              v-model.number="tr.quantity"/>
                  </template>
                </vs-td>

                <vs-td :data="tr.cost">
                  <template>
                    <vs-input disabled :max="tr.cost" style="width: 90px" type="number" v-model.number="tr.cost"/>
                  </template>
                </vs-td>

                <vs-td class="whitespace-no-wrap">
                  <feather-icon class="ml-2" icon="DownloadIcon"
                                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                @click.stop="exportToExcelTemplate(tr)"/>
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
          <!---->
        </template>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import Datepicker from 'vuejs-datepicker'

import { es } from 'vuejs-datepicker/dist/locale'
import _ from "lodash"
import { db, FieldValue } from "../../firebase/firebaseConfig"

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    providers: {
      type: Array,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    },
    costs: {
      type: Array,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  mixins: [trimString],
  data() {
    return {
      document: {
        base: 0,
        unaffected: 0,
        igv: 0,
        total: 0,
        currency: {
          symbol: ''
        },
        type: null,
        typeInvoice: null
      },
      types: [
        {
          id: 1,
          value: 'Mercadería, Materia prima, suministros diversos envases y embalajes.'
        },
        {
          id: 2,
          value: 'Activo fijo.'
        },
        {
          id: 3,
          value: 'Otros activos no considerados en los numerales 1 y 2.'
        },
        {
          id: 4,
          value: 'Gastos de educación, recreación, salud, culturales, representación, capacitación, de viaje, mantenimiento de vehículo y de premios.'
        },
        {
          id: 5,
          value: 'Otros gastos no incluidos en el numeral 4.'
        }
      ],
      currencies: [
        {
          id: 1,
          value: 'Soles',
          symbol: 'S/',
          code: 'PEN'
        },
        {
          id: 2,
          value: 'Dólares',
          symbol: '$',
          code: 'USD'
        }
      ],
      optionsIgv: [
        {
          id: 1,
          value: 'Adquisiciones gravadas destinadas a operaciones gravadas'
        },
        {
          id: 2,
          value: 'Adquisiciones gravadas destinadas a operaciones gravadas y no gravadas'
        },
        {
          id: 3,
          value: 'Adquisiciones gravadas destinadas a operaciones gravadas y no gravadas'
        },
        {
          id: 4,
          value: 'Aquisiciones no gravadas'
        },
        {
          id: 5,
          value: 'Adquisiciones con mas de un destino'
        }
      ],
      paymentMethods: [
        {
          id: '001',
          value: 'Contado'
        },
        {
          id: '002',
          value: 'Crédito'
        }
      ],
      typesInvoices: [
        {
          id: '01',
          value: 'Factura'
        },
        {
          id: '14',
          value: 'Recibo por servicios públicos'
        },
        {
          id: '13',
          value: ' Documentos emitidos por las empresas del sistema financiero y de seguros, y por las cooperativas de ahorro y crédito'
        },
        {
          id: '15',
          value: 'Boletos emitidos por el servicio de transporte terrestre '
        },
        {
          id: '12',
          value: 'Ticket o cinta emitido por máquina registradora'
        }
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      es: es
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.document = {
          ...this.data
        }
        this.addedProducts = []
        this.addedProducts = [...this.document.products]
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Export serials for print
     */
    async exportToExcelTemplate (p) {
      try {
        this.progress = true
        // Get Serials
        const querySnap = await db.collection('serials')
            .where('productId', '==', p.id)
            .where('purchaseInvoiceId', '==', this.document.id)
            .get()
        p.interialSerialsArray = []
        querySnap.forEach((doc) => {
          p.interialSerialsArray.push(doc.id)
        })
        // End

        import('@/vendor/Export2Excel').then(excel => {
          const list = _.cloneDeep(p.interialSerialsArray)
          const newList = []
          const headerVal = ['sku', 'serial']
          const headerTitle = ['sku', 'serial']
          list.forEach((l) => {
            newList.push({
              sku: p.sku,
              serial: l
            })
          })
          const data = this.formatJson(headerVal, newList)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: p.sku + '-Serials ' + this.document.serie + '-' + this.document.number + '-' + new Date().getTime(),
            autoWidth: this.cellAutoWidth,
            bookType: 'xlsx'
          })
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Save internal serials
     */
    async saveInternalSerials () {
      try {
        this.progress = true
        const batch = db.batch()
        const arrayBatch = []
        arrayBatch.push(batch)
        let currentBatch = 0
        let countDocuments = 0

        const formatForSerial = '0000'
        const formatForDoc = '0000000000'

        this.addedProducts.forEach((p) => {
          this.generateInternalSerials(p)
        })

        this.addedProducts.forEach((p) => {
          p.interialSerialsArray.forEach((s) => {
            const docRef = db.collection('serials').doc(s)
            arrayBatch[currentBatch].set(docRef, {
              createdAt: FieldValue.serverTimestamp(),
              dateOfPurchase: this.document.dateOfIssue,
              productId: p.id,
              productSku: p.sku,
              purchaseInvoiceId: this.document.id,
              purchaseInvoiceSerial: formatForSerial.substr(this.document.serie.length) + this.document.serie,
              purchaseInvoiceNumber: formatForDoc.substr(this.document.number.length) + this.document.number,
              cost: p.cost,
              currency: this.document.currency,
              state: true,
              provider: this.document.provider,
              status: 'free'
            })
            countDocuments++
            if (countDocuments === 499) {
              arrayBatch.push(db.batch())
              countDocuments = 0
              currentBatch++
            }
          })
          delete p.interialSerialsArray
        })

        // Commit batch
        for await (let b of arrayBatch) {
          await b.commit()
        }
        // End

        // Update invoice
        this.document.addedSeries = true
        this.$emit('update', this.document)
        await db.collection('invoices').doc(this.document.id).update({
          addedSeries: true,
          updatedSeries: true,
          updatedAt: FieldValue.serverTimestamp()
        })
        // End
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Generate internal serial
     */
    generateInternalSerials (product) {
      const uniqueSerials = []
      for (let i = 0; i < product.quantity; i++) {
        const timestamp = new Date().getTime() + i
        const randomCode = Math.floor(Math.random() * 10000)
        uniqueSerials.push(timestamp + randomCode + "")
      }
      product.interialSerialsArray = [...uniqueSerials]
    },
    /**
     * Format json
     * @param filterVal
     * @param jsonData
     * @returns {*}
     */
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 80vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
