<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Registrar pagos {{ invoice.serie + "-" + invoice.number }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Date-->
        <div>
          <label class="vs-select--label" for="paymentDate">Fecha de constancia</label>
          <datepicker :language="es" label="Fecha de constancia" v-model="document.paymentDate"
                      id="paymentDate"
                      v-validate="'required'" name="paymentDate"></datepicker>
          <span class="text-danger text-sm"
                v-show="errors.has('paymentDate')">{{ errors.first('paymentDate') }}</span>
        </div>
        <!---Info-->
        <div class="vx-row mt-5">
          <!--Number-->
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input @blur="document.number= trimString(document.number)"
                      label="Número de contancia"
                      v-model="document.number"
                      class="w-full" name="constPayment"
                      v-validate="'required|max:10|min:5'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('constPayment')">{{ errors.first('constPayment') }}</span>
          </div>
          <!--Total-->
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input type="number" label="Total"
                      v-model.number="document.total"
                      class="w-full" name="total"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('total')">{{ errors.first('total') }}</span>
          </div>
        </div>

        <vs-button :disabled="maxPay<=0" class="mt-5" @click="save">Agregar</vs-button>

        <hr class="mt-5 mb-5">
        <!--Last payments-->
        <div class="mt-8">
          <h6>Pagos realizados</h6>
          <vs-table class="mt-5" :data="payments">

            <template slot="thead">
              <vs-th>Fecha de registro</vs-th>
              <vs-th>Fecha de constancia</vs-th>
              <vs-th>Nro. Constancia</vs-th>
              <vs-th>Total</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.createdAt">
                  <p>{{ tr.createdAt | date(true) }}</p>
                </vs-td>

                <vs-td :data="tr.paymentDate">
                  <p>{{ tr.paymentDate | date(true) }}</p>
                </vs-td>

                <vs-td :data="tr.number">
                  <p> {{ tr.number }}</p>
                </vs-td>

                <vs-td :data="tr.total">
                  <p> {{ invoice.currency.symbol }} {{ Intl.NumberFormat().format(tr.total) }}</p>
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import Datepicker from 'vuejs-datepicker'

import {es} from 'vuejs-datepicker/dist/locale'

const _ = require('lodash')

import {db, FieldValue} from '@/firebase/firebaseConfig'

export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  mixins: [trimString],
  data() {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      invoice: {},
      document: {
        total: 0
      },
      maxPay: 0,
      payments: [],
      es: es,
      progress: false
    }
  },
  watch: {
    async isSidebarActive(val) {
      try {
        if (val) {
          this.progress = true
          this.document = {
            total: 0
          }
          this.payments = []
          this.maxPay = 0
          this.invoice = _.cloneDeep(this.data)
          await this.loadPayments()
          await this.getInvoice()
          this.maxPay = parseFloat((this.invoice.total - this.invoice.paidOut).toFixed(2))
          this.maxPay = parseFloat(this.maxPay.toFixed(2))
          this.document.total = _.cloneDeep(this.maxPay)
          await this.$validator.reset()
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     */
    async save() {
      try {
        if (this.document.total <= this.maxPay) {
          const result = await this.$validator.validateAll()
          if (result) {
            this.progress = true
            await db.collection('invoices').doc(this.invoice.id).collection('payments').add({
              ...this.document,
              createdAt: FieldValue.serverTimestamp(),
              invoiceId: this.invoice.id
            })
            this.payments.push({
              ...this.document,
              createdAt: new Date()
            })
            this.document = {
              total: 0
            }
            // Calculate max pay
            await this.getInvoice()
            this.maxPay = parseFloat((this.invoice.total - this.invoice.paidOut).toFixed(2))
            this.payments.forEach((p) => {
              this.maxPay -= p.total
            })
            this.maxPay = parseFloat(this.maxPay.toFixed(2))
            this.document.total = _.cloneDeep(this.maxPay)
            // End
            this.$vs.notify({
              color: 'success',
              title: 'Factura de compra',
              text: 'Pago registrado correctamente.'
            })
          }
        } else {
          this.$vs.notify({
            color: 'warning',
            title: 'Factura de compra',
            text: 'Total es mayor a adeudo.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Load payments
     */
    async loadPayments() {
      const querySnapshot = await db.collection("invoices").doc(this.invoice.id).collection('payments').get()
      querySnapshot.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          paymentDate: doc.data().paymentDate.toDate()
        }
        this.payments.push(obj)
      })
    },
    async getInvoice() {
      const doc = await db.collection('invoices').doc(this.invoice.id).get()
      this.invoice = {
        id: doc.id,
        ...doc.data()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 850px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
