<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Registrar detracción {{ document.serie + "-" + document.number }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Date-->
        <div>
          <label class="vs-select--label" for="paymentDate">Fecha de constancia</label>
          <datepicker :language="es" label="Fecha de constancia" v-model="document.detraction.paymentDate"
                      id="paymentDate"
                      v-validate="'required'" name="paymentDate"></datepicker>
          <span class="text-danger text-sm"
                v-show="errors.has('paymentDate')">{{ errors.first('paymentDate') }}</span>
        </div>
        <div class="vx-row mt-5">
          <!--Number-->
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input @blur="document.detraction.number= trimString(document.detraction.number)"
                      label="Número de contancia"
                      v-model="document.detraction.number"
                      class="w-full" name="constDetract"
                      v-validate="'required|max:10|min:5'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('constDetract')">{{ errors.first('constDetract') }}</span>
          </div>
          <!--ID-->
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input @blur="document.detraction.id= trimString(document.detraction.id)" label="ID"
                      v-model="document.detraction.id"
                      class="w-full" name="detractionId"
                      v-validate="'required|max:5|min:5'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('detractionId')">{{ errors.first('detractionId') }}</span>
          </div>
        </div>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="save" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import Datepicker from 'vuejs-datepicker'

import {es} from 'vuejs-datepicker/dist/locale'

const _ = require('lodash')

import {db, FieldValue} from '@/firebase/firebaseConfig'

export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  mixins: [trimString],
  data() {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      document: {
        detraction: {}
      },
      es: es,
      progress: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.document = _.cloneDeep(this.data)
        if (!this.document.detraction) {
          this.document.detraction = {}
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     */
    async save() {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          const format = '00000'
          await db.collection('invoices').doc(this.document.id).update({
            detraction: {
              ...this.document.detraction,
              id: format.substr(this.document.detraction.id.length) + this.document.detraction.id,
            },
            updatedAt: FieldValue.serverTimestamp()
          })
          this.$emit('update', this.document)
          this.$emit('closeSidebar')
          this.$vs.notify({
            color: 'success',
            title: 'Factura de compra',
            text: 'Detracción registrada correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 650px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
