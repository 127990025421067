<template>
    <div id="invoice-list" class="data-list-container">

        <div v-if="initProgress" class="w-100 mt-5 mb-5">
            <vs-progress indeterminate color="primary"></vs-progress>
        </div>

        <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
                 refresh-content-action @refresh="resetFilters">
            <h5 class="mt-5 mb-2">Filtrar por fecha</h5>
            <div class="vx-row">
                <div class="vx-col md:w-1/2">
                    <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                                v-validate="'required'" name="startDate"></datepicker>
                </div>
                <div class="vx-col md:w-1/2">
                    <datepicker :language="es" label="Gasta" v-model="endDate" id="endDate"
                                v-validate="'required'" name="startDate"></datepicker>
                </div>
                <div class="vx-col mt-2">
                    <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
                </div>
            </div>
        </vx-card>

        <new :orders="orders" :warehouses="warehouses" :products="products" :costs="costs" :accounts="accounts"
             :providers="providers"
             @add="addDataSideBar"
             :isSidebarActive="newSidebar" @closeSidebar="newSidebar=false"/>

        <detractions
                :data="sidebarData"
                @update="updateDataSideBar"
                :isSidebarActive="showDetractionSidebar"
                @closeSidebar="showDetractionSidebar=false"/>

        <payments
                :data="sidebarData"
                :isSidebarActive="showPaymentsSidebar"
                @closeSidebar="showPaymentsSidebar=false"/>

        <detail
                @update="updateDataSideBar"
                :warehouses="warehouses" :products="products" :costs="costs" :accounts="accounts" :providers="providers"
                :data="sidebarData"
                :isSidebarActive="showDetailSidebar"
                @closeSidebar="showDetailSidebar=false"/>

        <vs-table
                v-model="selected"
                pagination
                max-items="20"
                search
                stripe
                :data="list">

            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

                <div class="flex flex-wrap-reverse items-center data-list-btn-container">
                    <!-- Action - dropnow -->
                    <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

                        <div
                                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
                            <span class="mr-2">Acciones</span>
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
                        </div>

                        <vs-dropdown-menu>
                            <vs-dropdown-item @click="sidebarData= selected,  showDetractionSidebar= true">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Registrar detrac.</span>
                            </span>
                            </vs-dropdown-item>

                            <vs-dropdown-item @click="sidebarData= selected,  showPaymentsSidebar= true">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Registrar pago</span>
                            </span>
                            </vs-dropdown-item>

                            <vs-dropdown-item @click="exportToExcel">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar resumen</span>
                            </span>
                            </vs-dropdown-item>

                            <vs-dropdown-item @click="exportToQlik">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar detalle</span>
                            </span>
                            </vs-dropdown-item>

                        </vs-dropdown-menu>
                    </vs-dropdown>

                    <!-- Add new -->
                    <div
                            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
                            @click="newSidebar=true">
                        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
                        <span class="ml-2 text-base text-primary">Agregar</span>
                    </div>
                </div>
            </div>

            <template slot="thead">
                <vs-th sort-key="type">Tipo</vs-th>
                <vs-th sort-key="createdAt">Fec. de registro</vs-th>
                <vs-th sort-key="dateOfIssue">Fec. de emisión</vs-th>
                <vs-th sort-key="dateOfIssue">Fec. de vencimiento</vs-th>
                <vs-th sort-key="serie">Serie doc.</vs-th>
                <vs-th sort-key="nroDoc">Nro. doc</vs-th>
                <vs-th sort-key="provider">Proveedor</vs-th>
                <vs-th sort-key="currency">Moneda</vs-th>
                <vs-th sort-key="baseImp">Base imponible</vs-th>
                <vs-th sort-key="innafect">Inafecto</vs-th>
                <vs-th sort-key="igv">IGV</vs-th>
                <vs-th sort-key="total">Total</vs-th>
                <vs-th sort-key="paymentMethod">Forma de pago</vs-th>
                <vs-th>Acciones</vs-th>
            </template>

            <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td>
                    <p>{{ tr.typeInvoice.value }}</p>
                  </vs-td>

                  <vs-td>
                    <p>{{ tr.createdAt }}</p>
                  </vs-td>

                  <vs-td>
                    <p>{{ tr.dateOfIssue | date(true) }}</p>
                    </vs-td>

                    <vs-td>
                        <p>{{ tr.endDate | date(true) }}</p>
                    </vs-td>

                    <vs-td>
                        <p> {{ tr.serie }} </p>
                    </vs-td>

                    <vs-td>
                        <p> {{ tr.number }} </p>
                    </vs-td>

                    <vs-td>
                        <p>{{ tr.provider.name }}</p>
                    </vs-td>

                    <vs-td>
                        <p>{{ tr.currency.value }}</p>
                    </vs-td>

                    <vs-td>
                        <p>{{
                            Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: `${tr.currency.code}`
                            }).format(tr.base)
                            }}</p>
                    </vs-td>

                    <vs-td>
                        <p>{{
                            Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: `${tr.currency.code}`
                            }).format(tr.unaffected)
                            }}</p>
                    </vs-td>

                    <vs-td>
                        <p>{{
                            Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: `${tr.currency.code}`
                            }).format(tr.igv)
                            }}</p>
                    </vs-td>

                    <vs-td>
                        <p>{{
                            Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: `${tr.currency.code}`
                            }).format(tr.total)
                            }}</p>
                    </vs-td>

                    <vs-td>
                        <p>{{ tr.paymentMethod.value }}</p>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                        <feather-icon title="Detalle" icon="EyeIcon"
                                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                      @click.stop="sidebarData= tr, showDetailSidebar= true"/>
                    </vs-td>
                </vs-tr>
                </tbody>
            </template>
        </vs-table>
    </div>
</template>

<script>
import New from './New'
import Detail from './Detail'
import Detractions from './Detractions'
import Payments from './Payments'
import Datepicker from 'vuejs-datepicker'

import { db, Timestamp } from '@/firebase/firebaseConfig'
import accounts from '../../data/acounts.json'
import costs from '../../data/costs.json'

import { es } from 'vuejs-datepicker/dist/locale'
import moment from "moment";

const _ = require('lodash')

accounts.forEach((a) => {
  a.label = a.id + " - " + a.value
})

costs.forEach((c) => {
  c.label = c.id + " - " + c.value
})

export default {
    components: {
        New,
        Detail,
        Detractions,
        Payments,
        Datepicker
    },
    data() {
        return {
            selected: null,
            itemsPerPage: 10,
            isMounted: false,
            list: [],
            initProgress: false,
            providers: [],
            accounts: accounts,
            costs: costs,
            products: [],
            warehouses: [],
            startDate: null,
            endDate: null,
            es: es,
            orders: [],

            // Data Sidebar
            newSidebar: false,
            showDetailSidebar: false,
            showDetractionSidebar: false,
            showPaymentsSidebar: false,
            sidebarData: {}
        }
    },
    computed: {
        currentPage() {
            if (this.isMounted) {
                return this.$refs.table.currentx
            }
            return 0
        },
        queriedItems() {
            return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
        }
    },
    async created() {
        try {
            this.initProgress = true
            await this.loadInvoices()
            await this.loadProducts()
            await this.loadProviders()
            await this.loadWarehouses()
            await this.loadOrders()
        } catch (e) {
            console.log(e)
            this.$vs.notify({
                color: 'warning',
                title: '¡Oh no!',
                text: 'Hubo un error.'
            })
        } finally {
            this.initProgress = false
        }
    },
    mounted() {
        this.isMounted = true
    },
    methods: {
        /**
         * Add data of sidebar
         * */
        addDataSideBar(o) {
            this.list.unshift(o)
        },
        /**
         * Update data of sidebar
         * */
        updateDataSideBar(o) {
            const index = this.list.findIndex((d) => d.id === o.id)
            Object.assign(this.list[index], o)
        },
        /**
         * Export to excel
         */
        exportToExcel() {
            import('@/vendor/Export2Excel').then(excel => {
                const list = _.cloneDeep(this.list)
                list.forEach((i) => {
                    i.status = 0
                    i.fechregistro = i.createdAt.getDate() + "/" + (i.createdAt.getMonth() + 1) + "/" + i.createdAt.getFullYear()
                    i.fechdoc = i.dateOfIssue.getDate() + "/" + (i.dateOfIssue.getMonth() + 1) + "/" + i.dateOfIssue.getFullYear()
                    i.tipdoc = i.typeInvoice.id
                    i.serdoc = i.serie
                    i.numdoc = i.number
                    i.tipdid = i.provider.typeDocument === 'RUC' ? '6' : '1'
                    i.nmruc = i.provider.document
                    i.ctactename = i.provider.name
                    i.afectoigvid = i.applyIgv.value
                    i.baseimpo = i.typeInvoice.id === '07' ? i.base * -1 : i.base
                    i.inafecto = i.typeInvoice.id === '07' ? i.unaffected * -1 : i.unaffected
                    i.pigv = 18
                    i.migv = i.typeInvoice.id === '07' ? i.igv * -1 : i.igv
                    i.total = i.typeInvoice.id === '07' ? i.total * -1 : i.total
                    i.cuentaid = i.account.id
                    i.glosa = i.gloss
                    i.moneda = i.currency.id
                    i.tipref = ''
                    i.serref = ''
                    i.numref = ''
                    i.fechref = ''
                    i.nconsdetrac = i.detraction ? i.detraction.number : ''
                    i.fechconstdetrac = i.detraction ? i.detraction.paymentDate.getDate() + "/" + (i.detraction.paymentDate.getMonth() + 1) + "/" + i.detraction.paymentDate.getFullYear() : ''
                    i.detraccionid = i.detraction ? i.detraction.id : ''
                    i.condicionpago = ''
                    i.fechpago = i.dateOfIssue.getDate() + "/" + (i.dateOfIssue.getMonth() + 1) + "/" + i.dateOfIssue.getFullYear()
                    i.cencosid = i.cost.id
                    i.cuentaidt = i.account.id
                    i.compradorid = ''
                    i.bienesyservicios = i.type.id
                })
                const headerVal = ['status', 'fechregistro', 'fechdoc', 'tipdoc', 'serdoc', 'numdoc', 'tipdid', 'nmruc', 'ctactename', 'afectoigvid', 'baseimpo', 'inafecto', 'pigv', 'migv', 'total', 'cuentaid', 'glosa', 'moneda', 'tipref', 'serref', 'numref', 'fechref', 'nconsdetrac', 'fechconstdetrac', 'detraccionid', 'condicionpago', 'fechpago', 'cencosid', 'cuentaidt', 'compradorid', 'bienesyservicios']
                const headerTitle = ['status', 'fechregistro', 'fechdoc', 'tipdoc', 'serdoc', 'numdoc', 'tipdid', 'nmruc', 'ctactename', 'afectoigvid', 'baseimpo', 'inafecto', 'pigv', 'migv', 'total', 'cuentaid', 'glosa', 'moneda', 'tipref', 'serref', 'numref', 'fechref', 'nconsdetrac', 'fechconstdetrac', 'detraccionid', 'condicionpago', 'fechpago', 'cencosid', 'cuentaidt', 'compradorid', 'bienesyservicios']
                const data = this.formatJson(headerVal, list)
                excel.export_json_to_excel({
                    header: headerTitle,
                    data,
                    filename: 'Registro de compras',
                    autoWidth: this.cellAutoWidth,
                    bookType: 'xlsx'
                })
            })
        },
        /**
         * Export to qlik
         */
        exportToQlik() {
            import('@/vendor/Export2Excel').then(async excel => {
                this.initProgress = true
                const list = _.cloneDeep(this.list)
                const newList = []
                list.forEach((l) => {
                    if (l.typeInvoice.id === '01') {
                        const index = newList.findIndex((n) => (n.serie === l.serie) && n.number === l.number)
                        if (index === -1) {
                            newList.push(l)
                        }
                    }
                })
                const listForExcel = []
                newList.forEach((l) => {
                    l.products.forEach((p) => {
                        listForExcel.push({
                            sku: this.products.find((p2) => p.id == p2.id) ? this.products.find((p2) => p.id == p2.id).sku : 'NN',
                            name: this.products.find((p2) => p.id == p2.id) ? this.products.find((p2) => p.id == p2.id).name : 'NN',
                            category: this.products.find((p2) => p.id == p2.id) ? this.products.find((p2) => p.id == p2.id).category.name : 'NN',
                            brand: this.products.find((p2) => p.id == p2.id) ? this.products.find((p2) => p.id == p2.id).brand.name : 'NN',
                            dateOfIssue: l.dateOfIssue.getFullYear() + '/' + (l.dateOfIssue.getMonth() + 1) + '/' + l.dateOfIssue.getDate(),
                            year: l.dateOfIssue.getFullYear(),
                            month: this.getMonth(l.dateOfIssue.getMonth()),
                            order: l.dateOfIssue.getMonth(),
                            quantity: p.quantity,
                            singlePrice: l.currency.code === 'PEN' ? p.cost : p.cost * 3.7,
                            discount: 0,
                            total: l.currency.code === 'PEN' ? (p.cost * p.quantity) : (p.cost * 3.7 * p.quantity),
                            local: l.warehouse.name,
                            doc: l.serie + '-' + l.number,
                            provider: l.provider.name
                        })
                    })
                })
                const headerVal = ['sku', 'name', 'category', 'brand', 'dateOfIssue', 'year', 'month', 'quantity', 'singlePrice', 'total', 'provider', 'doc']
                const headerTitle = ['SKU', 'Nombre', 'Categoría', 'Marca', 'Fecha de compra', 'Año', 'Mes', 'Cantidad', 'Costo', 'Costo Total', 'Proveedor', 'Documento']
                const data = this.formatJson(headerVal, listForExcel)
                excel.export_json_to_excel({
                    header: headerTitle,
                    data,
                    filename: 'Qlik Sense',
                    autoWidth: this.cellAutoWidth,
                    bookType: 'xlsx'
                })
                this.initProgress = false
            })
        },
        getMonth(month) {
            switch (month) {
                case 0:
                    return 'Enero'
                case 1:
                    return 'Febrero'
                case 2:
                    return 'Marzo'
                case 3:
                    return 'Abril'
                case 4:
                    return 'Mayo'
                case 5:
                    return 'Junio'
                case 6:
                    return 'Julio'
                case 7:
                    return 'Agosto'
                case 8:
                    return 'Septiembre'
                case 9:
                    return 'Octubre'
                case 10:
                    return 'Noviembre'
                case 11:
                    return 'Diciembre'
            }
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => {
                // Add col name which needs to be translated
                // if (j === 'timestamp') {
                //   return parseTime(v[j])
                // } else {
                //   return v[j]
                // }

                return v[j]
            }))
        },
      /**
       * get providers
       * @returns {Promise<void>}
       */
        async loadProviders() {
          const querySnapshot = await db.collection("providers")
              .where('state', '==', true)
              .where('typeDocument', '==', 'RUC')
              .orderBy('createdAt', 'desc').get()
          querySnapshot.forEach((doc) => {
            let obj = {
              id: doc.id,
              ...doc.data(),
              label: doc.data().document + " - " + doc.data().name
            }
            this.providers.push(obj)
          })
        },
        /**
         * Get products
         * @returns {Promise<void>}
         */
        async loadProducts() {
            this.products = []
            const querySnapshot = await db.collection("products").where('state', '==', true).orderBy('createdAt', 'desc').get()
            querySnapshot.forEach((doc) => {
                let product = {
                    id: doc.id,
                    brand: doc.data().brand,
                    category: doc.data().category,
                    name: doc.data().name,
                    sku: doc.data().sku,
                    label: doc.data().name + " - " + doc.data().sku
                }
                this.products.push(product)
            })
        },
        /**
         * Get warehouses
         * @returns {Promise<void>}
         */
        async loadWarehouses() {
            this.warehouses = []
            const querySnapshot = await db.collection("warehouses").where('state', '==', true).orderBy('createdAt', 'desc').get()
            querySnapshot.forEach((doc) => {
                let warehouse = {
                    id: doc.id,
                    name: doc.data().name,
                    address: doc.data().address,
                    ruc: doc.data().ruc,
                    department: doc.data().department,
                    province: doc.data().province,
                    district: doc.data().district,
                    ubigeo: doc.data().ubigeo
                }
                this.warehouses.push(warehouse)
            })
        },
        /**
         * Get invoices
         * */
        async loadInvoices() {
            const querySnapshot = await db.collection("invoices").orderBy('createdAt', 'desc').limit(100).get()
            querySnapshot.forEach((doc) => {
                let obj = {
                    id: doc.id,
                    ...doc.data(),
                  createdAt: moment(doc.data().createdAt.toDate()).format('YYYY-MM-DD HH:mm'),
                    dateOfIssue: doc.data().dateOfIssue.toDate(),
                    endDate: doc.data().endDate.toDate()
                }
                if (obj.detraction) {
                    obj.detraction.paymentDate = obj.detraction.paymentDate.toDate()
                }
                this.list.push(obj)
            })
        },
        /**
         * Filter by date
         */
        async filterByDate() {
            try {
                if (this.startDate && this.endDate) {
                    this.initProgress = true
                    this.list = []
                    // Set start date
                    this.startDate.setHours(0)
                    this.startDate.setMinutes(0)
                    // Set end date
                    this.endDate.setHours(23)
                    this.endDate.setMinutes(59)

                    this.startDate.set
                    const starTimes = Timestamp.fromDate(this.startDate)
                    const endTimes = Timestamp.fromDate(this.endDate)
                    const querySnapshot = await db.collection('invoices')
                        .where('dateOfIssue', '>=', starTimes)
                        .where('dateOfIssue', '<=', endTimes).get()
                    querySnapshot.forEach((doc) => {
                        this.list.push({
                            id: doc.id,
                            ...doc.data(),
                            createdAt: doc.data().createdAt.toDate(),
                            dateOfIssue: doc.data().dateOfIssue.toDate(),
                            endDate: doc.data().endDate.toDate()
                        })
                    })
                }
            } catch (e) {
                this.$vs.notify({
                    color: 'warning',
                    title: '¡Oh no!',
                    text: 'Hubo un error.'
                })
            } finally {
                this.initProgress = false
            }
        },
        /**
         * Reset filters
         * @returns {Promise<void>}
         */
        async resetFilters() {
            this.initProgress = true
            this.list = []
            this.startDate = null
            this.endDate = null
            await this.loadInvoices()
            this.$refs.filterCard.removeRefreshAnimation()
            this.initProgress = false
        },
        /**
         * Load orders of firestore
         * */
        async loadOrders() {
            const querySnapshot = await db.collection("orders").orderBy('createdAt', 'desc').limit(20).get()
            querySnapshot.forEach((doc) => {
                let obj = {
                    id: doc.id,
                    numberOfDocument: doc.data().numberOfDocument
                }
                obj.createdAt = doc.data().createdAt.toDate()
                this.orders.push(obj)
            })
        }
    }
}
</script>

<style lang="scss">
#invoice-list {

  .vs-table--tbody-table {
    min-width: 1700px !important;
  }

  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
